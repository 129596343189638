<template class="watermark">
  <invoice-view v-if="order && invoiceCustomInformation"
                :company="company"
                :order="order"
                :company-owner="companyOwner"
                :customInvoiceConfiguration="invoiceCustomInformation"
                :invoice-titles="invoiceTitles"
                :show-address-section="showAddressSection"
                is-to-pdf/>
</template>

<script>

import InvoiceView from '@/views/dashboard/credit-order-detail/InvoiceView.vue';
import {get} from '@/services/api';
import {INVOICE_INFORMATION_DEFUALT, invoicesTitlesDistributors, invoicesTitlesTvs} from '@/constants';

export default {
  name: 'Invoice',
  components: {InvoiceView},
  data: () => ({
    order: null,
    showAddressSection: true,
    company: null,
    intentPayment: null,
    companyOwner: INVOICE_INFORMATION_DEFUALT,
    extraFeePaymentMethod: 0,
    extraFeePrice: 0,
    totalPriceOrder: 0,
    invoiceCustomInformation: {
      companyName: {status: true, value: ''},
      vatNumber: {status: false, value: ''},
      attentionTo: {status: true, value: ''},
      address1: {status: true, value: ''},
      address2: {status: true, value: ''},
      city: {status: true, value: ''},
      zipCode: {status: true, value: ''},
      country: {status: true, value: ''}
    },
    invoiceTitles: invoicesTitlesDistributors(),
  }),
  mounted() {
    this.load();
  },
  methods: {
    load() {
      let id = this.$route.params.id;
      get(`site/order-software/${id}`)
        .then(async ({data}) => {
          this.order = data.order;
          this.order.extraFee = Number(this.order.extraFee)
          await this.loadCustomInformation(data.order.userId, data.company)
          this.intentPayment = data.intentPayment;
          this.invoiceTitles = (this.order.ownerId === null) ? invoicesTitlesTvs() : invoicesTitlesDistributors();
          this.showAddressSection = this.order.ownerId === null;
          if (data.companyOwner) {
            this.companyOwner = JSON.parse(data.companyOwner.extraData);
          }
          this.setCurrency()
          if (this.intentPayment !== null) {
            this.calculatePrice(this.intentPayment)
          }
        });
    },
    async loadCustomInformation(userId, company) {
      this.company = company;
      const {data} = await get(`site/custom-invoice-information?id=${userId}`)
      if (data) {
        this.invoiceCustomInformation = data.information
        this.company.address = data.information.address1.value
        this.company.address2 = data.information.address2.value
        this.company.legalRepresentative = data.information.attentionTo.value
        this.company.city = data.information.city.value
        this.company.nameCompany = data.information.companyName.value
        this.company.country = data.information.country.value
        this.company.vatNumber = data.information.vatNumber.value
        this.company.zipCode = data.information.zipCode.value
      }
    },
    setCurrency() {
      this.$store.commit('geo/setUserCurrency', {
        ...this.$store.state.geo.tvsCurrency,
        locale: this.order.currencyDetails.locale
      })
    },
    calculatePrice(payment) {
      if (payment.payment_method === 'sepa_debit') {
        this.totalPriceOrder = this.order.price
        this.extraFeePrice = 0
        this.extraFeePaymentMethod = 0
      } else {
        this.totalPriceOrder = ((this.order.price * payment.fee) / 100) + this.order.price
        this.extraFeePrice = this.totalPriceOrder - this.order.price
        this.extraFeePaymentMethod = payment.fee
      }
    },
  },
  watch: {
    '$route.params'() {
      this.load();
    }
  }
};
</script>
<style scoped lang="scss">

</style>
